import { Injectable } from '@angular/core';
import { DataStoreService } from 'src/app/data-store';
import { ApiService } from 'src/app/api';
import { Authority } from './model/authority';
import { Observable, of, combineLatest } from 'rxjs';
import { switchMap, shareReplay, map, filter } from 'rxjs/operators';
import { RlasApiModule } from './rlas-api.module';
import {
  ManagementGroup,
  PropertyValue,
  ManagementProperty,
  AuditRecord,
  User,
  SecurityGroup,
  Tag,
  AuthorityGroup,
  KnownAuthorityType,
  PresentationAuthorityType,
  Development
} from './model';
import { HistoryFilter } from './model/history-filter';
import * as _ from 'lodash';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DataRefreshChaseValue } from 'src/app/rlas-api';

/**
 * A service to support data access for Local Authority Data.
 */
export interface IAuthorityService {

  /**
   * A stream of all Authorities.
   */
  readonly authorities$: Observable<Authority[]>;

  /**
   * A stream of the currently Local Authorities.
   */
  readonly localAuthorities$: Observable<Authority[]>;

  /**
   * A stream of the Authorities group be their type.
   */
  readonly authorityDictionary$: Observable<Map<string, Authority[]>>;

  /**
   * A stream of the all properties.
   */
  readonly allProperties$: Observable<ManagementProperty[]>;

  /**
   * A stream of the currently available Authority Groups.
   */
  readonly authorityGroupNames$: Observable<AuthorityGroup[]>;

  /**
   * Creates an observable returning property values for the selected group.
   *
   * @param group A group to return the property values for.
   * @returns An observable emitting an array of group property values.
   */
  getGroupPropertyValues$(group: ManagementGroup): Observable<PropertyValue[]>;

  /**
   * Creates an observable returning property values for the selected development.
   *
   * @param group A group to return the property values for.
   * @param developmentId Development Id.
   * @returns An observable emitting an array of group property values.
   */
  getGroupPropertyValuesByDevelopmentId$(group: ManagementGroup, developmentId: string);

  /**
   * Creates an observable returning data refresh chases values for the data refresh group.
   *
   * @param authority An authority to return the data refresh chases values for.
   * @returns An observable emitting an array of authority chases values.
   */
  getAuthorityChaseValues$(authority: Authority): Observable<DataRefreshChaseValue[]>;

  /**
   * Creates an observable returning available properties for the selected group.
   *
   * @param group A group to return the properties for.
   * @returns An observable emitting an array of group properties.
   */
  getGroupProperties$(group: ManagementGroup): Observable<ManagementProperty[]>;

  /**
   * Creates an observable returning available tags for the selected authority.
   *
   * @param authority An authority to get the tags for.
   * @returns An observable emitting an array of group properties.
   */
  getTags$(authority: Authority): Observable<Tag[]>;

  /**
   * Gets the active tag for a give authority.
   *
   * @param authority The {@link Authority} to get the tags for.
   * @returns A stream of the active tags.
   */
  getActiveTag$(authority: Authority): Observable<Tag>;

  /**
   * Gets the geometry for a given authority.
   *
   * @param authority The authority.
   * @returns The geometry for the authority.
   */
  getGeometry$(authority: Authority): Observable<jsts.geom.Geometry>;

  /**
   * Creates an observable returning property values for the selected group by tag name.
   *
   * @param group A group to return the property values for.
   * @param tagName The name of the tag to filter on.
   * @returns An observable emitting an array of group property values.
   */
  getGroupPropertyValuesByTagName$(group: ManagementGroup, tagName: string): Observable<PropertyValue[]>;

}

/**
 * A service to support data access for Local Authority Data.
 */
@Injectable({
  providedIn: RlasApiModule
})
export class AuthorityService implements IAuthorityService {

  private authorityGeometries$ = new Map<Authority, Observable<jsts.geom.Geometry>>();

  /**
   * Creates an instance of value change authority service.
   *
   * @param apiService The {@link ApiService} instance to use.
   * @param userService The {@link UserService} instance to use.
   * @param dataStoreService The {@link DataStoreService} instance to use.
   * @param http The {@link HttpClient} instance to use.
   */
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private dataStoreService: DataStoreService,
    private http: HttpClient) { }

  /**
   * A map of known authority types and  their authority types that their associated with.
   */
  private readonly authorityTypeMap = (
    {
      [PresentationAuthorityType.EditableAuthority]: [
        KnownAuthorityType.CountyCouncil,
        KnownAuthorityType.Global,
        KnownAuthorityType.Highways,
        KnownAuthorityType.LocalAuthority,
        KnownAuthorityType.NationalPark,
        KnownAuthorityType.WaterCompany,
      ],
      [KnownAuthorityType.LocalAuthority]: [
        KnownAuthorityType.CountyCouncil,
        KnownAuthorityType.Global,
        KnownAuthorityType.Highways,
        KnownAuthorityType.LocalAuthority,
        KnownAuthorityType.NationalPark,
      ],
      [KnownAuthorityType.Agent]: [KnownAuthorityType.Agent],
      [KnownAuthorityType.CountyCouncil]: [KnownAuthorityType.CountyCouncil],
      [KnownAuthorityType.Global]: [KnownAuthorityType.Global],
      [KnownAuthorityType.Highways]: [KnownAuthorityType.Highways],
      [KnownAuthorityType.NationalPark]: [
        KnownAuthorityType.CountyCouncil,
        KnownAuthorityType.Global,
        KnownAuthorityType.Highways,
        KnownAuthorityType.LocalAuthority,
        KnownAuthorityType.NationalPark,
      ],
      [KnownAuthorityType.WaterCompany]: [KnownAuthorityType.WaterCompany],
    });

  /**
   * A list of agents which can currently be used.
   */
  private readonly activeAgents = [
    'Search Acumen'
  ];

  /**
   * @inheritdoc
   */
  public readonly authorityGroupNames$ = this.apiService.api$.pipe(
    switchMap(api => this.dataStoreService.loadCollection<AuthorityGroup>(`${api.AuthoritiesController}/groups`)),
    switchMap(authorityGroups =>
      combineLatest(authorityGroups.data.map(group => this.dataStoreService.loadObject<AuthorityGroup>(group.href)))
    ),
    map(authorityGroups => authorityGroups.map(group => group.data)),
    shareReplay({ refCount: false, bufferSize: 1 }),
  );

  /**
   * @inheritdoc
   */
  public readonly authorities$ = this.apiService.api$.pipe(
    switchMap(x => this.dataStoreService.loadCollection<Authority>(x.AuthoritiesController)),
    switchMap(authorities => this.dataStoreService.loadStoreObjectsData(authorities.data)),
    map(authorities => _.sortBy(authorities, [(a): number => this.getTypeOrder(a.typeName), (a): string => a.name])),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * @inheritdoc
   */
  public readonly authorityDictionary$ = this.authorities$.pipe(
    map(authorities => Object.entries(this.authorityTypeMap).map(([authorityTypeMapName, supportedAuthoritTypes]) => ({
      authorityType: authorityTypeMapName,
      authorities: [...authorities].filter(b => supportedAuthoritTypes.includes(b.typeName))
    })
    )),
    map(authorities => new Map(
      [...authorities].map(a => [
        a.authorityType,
        a.authorities
      ]))),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A stream of the currently available Local Authorities.
   */
  public readonly localAuthorities$ = this.authorities$.pipe(
    map(authorities => authorities.filter(a =>
      this.authorityTypeMap[KnownAuthorityType.LocalAuthority].includes(a.typeName))),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A stream of the currently available Agents.
   */
  public readonly agents$ = this.authorities$.pipe(
    map(authorities => authorities.filter(a =>
      this.authorityTypeMap[KnownAuthorityType.Agent].includes(a.typeName) &&
      this.activeAgents.includes(a.name))),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A stream of all the properties.
   */
  public readonly allProperties$ = this.apiService.api$.pipe(
    switchMap(x => this.dataStoreService.loadObject<ManagementProperty[]>(x['RlasManagementController.GetAllProperties'])),
    map(x => x.data),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * Group access view details.
   */
  public readonly groupAccessView = {
    ['overview']: null,
    ['general']: SecurityGroup.rlasLaDetailsAccess,
    ['departments']: null,
    ['split-config']: SecurityGroup.rlasSplitConfigAccess,
    ['default-answers']: SecurityGroup.rlasDefaultAnswersAccess,
    ['default-documents']: SecurityGroup.rlasDefaultDocumentsAccess,
    ['help']: SecurityGroup.rlasHelpTextAccess,
    ['data-rules']: SecurityGroup.rlasDataRulesAccess,
    ['shortcodes']: SecurityGroup.rlasShortcodesAccess,
    ['map-layers']: null,
    ['history']: null,
    ['kaizen-help']: SecurityGroup.rlasKaizenHelpTextAccess,
    ['mapping']: SecurityGroup.rlasMappingAccess,
    ['searcher-allocation']: SecurityGroup.rlasSearcherAllocationAccess,
    ['toolbox']: SecurityGroup.rlasToolboxAccess,
    ['development-help']: SecurityGroup.rlasDevelopmentHelpAccess,
    ['data-refresh']: SecurityGroup.rlasDataRefreshAccess,
    ['auto-search']: SecurityGroup.rlasAutoSearchAccess,
    ['ace-settings']: SecurityGroup.rlasAceAccess,
    ['auto-allocations']: SecurityGroup.rlasAceAccess,
  };

  /**
   * Group access edit details.
   */
  public readonly groupAccessEdit = {
    ['overview']: null,
    ['general']: SecurityGroup.rlasLaDetailsEdit,
    ['departments']: null,
    ['split-config']: SecurityGroup.rlasSplitConfigEdit,
    ['default-answers']: SecurityGroup.rlasDefaultAnswersEdit,
    ['default-documents']: SecurityGroup.rlasDefaultDocumentsEdit,
    ['help']: SecurityGroup.rlasHelpTextEdit,
    ['data-rules']: SecurityGroup.rlasDataRulesEdit,
    ['shortcodes']: SecurityGroup.rlasShortcodesEdit,
    ['map-layers']: null,
    ['history']: null,
    ['kaizen-help']: SecurityGroup.rlasKaizenHelpTextEdit,
    ['mapping']: SecurityGroup.rlasMappingEdit,
    ['searcher-allocation']: SecurityGroup.rlasSearcherAllocationEdit,
    ['toolbox']: SecurityGroup.rlasToolboxEdit,
    ['development-help']: SecurityGroup.rlasDevelopmentHelpEdit,
    ['data-refresh']: SecurityGroup.rlasDataRefreshEdit,
    ['auto-search']: SecurityGroup.rlasAutoSearchEdit,
    ['ace-settings']: SecurityGroup.rlasAceEdit,
    ['auto-allocations']: SecurityGroup.rlasAceEdit,
  };

  /**
   * Gets a stream of management groups.
   *
   * @param authority The {@link Authority} to get the groups for.
   * @returns A stream of management groups.
   */
  public getGroups$(authority: Authority): Observable<ManagementGroup[]> {
    return combineLatest([this.userService.me$, this.dataStoreService.loadObject<ManagementGroup[]>(authority.managementGroups.href)]).pipe(
      map(([user, groups]) => groups.data.map(g => this.hasGroupAccess(user, g))),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  /**
   * Gets a stream of audit records.
   *
   * @param authority The {@link Authority} to get the audit records for.
   * @param historyFilter The {@link HistoryFilter} to get the audit records for.
   * @returns Stream of audit records.
   */
  public getHistory$(authority: Authority, historyFilter: HistoryFilter): Observable<AuditRecord[]> {
    return this.dataStoreService.loadObjectNoCache<AuditRecord[]>(authority.managementHistory.href, historyFilter).pipe(
      map(history => history.data),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  /**
   * Creates an observable returning property values for the selected group.
   *
   * @param group A group to return the property values for.
   * @returns An observable emitting an array of group property values.
   */

  /**
   * @inheritdoc
   */
  public getGroupPropertyValues$(group: ManagementGroup): Observable<PropertyValue[]> {
    return group?.propertyValues
      ? this.dataStoreService.loadObject<PropertyValue[]>(group.propertyValues.href).pipe(
        map(groups => groups.data),
        shareReplay({ refCount: false, bufferSize: 1 }))
      : of([]).pipe(shareReplay({ refCount: false, bufferSize: 1 }));
  }

  /**
   * @inheritdoc
   */
  public getGroupPropertyValuesByDevelopmentId$(group: ManagementGroup, developmentId: string): Observable<PropertyValue[]> {
    return of(group.propertyValueDevelopments.find(m => m.developmentId === developmentId)?.propertyValues.href).pipe(
      filter(developmentUrl => developmentUrl != null),
      switchMap(developmentUrl => this.dataStoreService.loadObject<PropertyValue[]>(developmentUrl)),
      map(groups => groups.data),
      shareReplay({ refCount: false, bufferSize: 1 }));
  }

  /**
   * @inheritdoc
   */
  public getAuthorityChaseValues$(authority: Authority): Observable<DataRefreshChaseValue[]> {
    return authority?.chaseData
      ? this.dataStoreService.loadObject<DataRefreshChaseValue[]>(authority.chaseData.href).pipe(
        map(groups => groups.data),
        shareReplay({ refCount: false, bufferSize: 1 }))
      : of([]).pipe(shareReplay({ refCount: false, bufferSize: 1 }));
  }

  /**
   * @inheritdoc
   */
  public getGroupPropertyValuesByTagName$(group: ManagementGroup, tagName: string): Observable<PropertyValue[]> {
    return this.getGroupPropertyValues$(group).pipe(
      map(m => m.filter(n => n.tags.includes(tagName))),
      shareReplay({ refCount: false, bufferSize: 1 }));
  }

  /**
   * Creates an observable returning available properties for the selected group.
   *
   * @param group A group to return the properties for.
   * @returns An observable emitting an array of group properties.
   */
  public getGroupProperties$(group: ManagementGroup): Observable<ManagementProperty[]> {
    return group.properties
      ? this.dataStoreService.loadObject<ManagementProperty[]>(group.properties.href).pipe(
        map(properties => properties.data),
        shareReplay({ refCount: false, bufferSize: 1 }))
      : of([]).pipe(shareReplay({ refCount: false, bufferSize: 1 }));
  }

  /**
   * Gets the tags for a give authority.
   *
   * @param authority The {@link Authority} to get the tags for.
   * @returns A stream of tags.
   */
  public getTags$(authority: Authority): Observable<Tag[]> {
    return this.dataStoreService.loadObject<Tag[]>(authority.configurations.href).pipe(
      map(tags => tags.data),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  /**
   * Gets the developments for a give authority.
   *
   * @param authority The {@link Authority} to get the developments for.
   * @returns A stream of developments.
   */
  public getDevelopments$(authority: Authority): Observable<Development[]> {
    return this.dataStoreService.loadObject<Development[]>(authority.developments.href).pipe(
      map(developments => developments.data),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  /**
   * @inheritdoc
   */
  public getActiveTag$(authority: Authority): Observable<Tag> {
    return this.getTags$(authority).pipe(
      map(tags => tags.find(m => m.isActive && !m.isDeleted)),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  /**
   * Determines whether the user has access to the group.
   *
   * @param user The {@link User} to check the access details for.
   * @param group The {@link ManagementGroup} to check the access details for.
   * @returns Management group contains the details.
   */
  public hasGroupAccess(user: User, group: ManagementGroup): ManagementGroup {
    group.allowedForUser = this.groupAccessView[group.name] === null || user.groups.includes(this.groupAccessView[group.name]);
    return group;
  }

  /**
   * Gets the geometry for a given authority.
   *
   * @param authority The authority.
   * @returns The geometry for the authority.
   */
  public getGeometry$(authority: Authority): Observable<jsts.geom.Geometry> {
    if (this.authorityGeometries$.has(authority)) {
      return this.authorityGeometries$.get(authority);
    } else {
      // eslint-disable-next-line max-len
      const laBoundaryWfsUri = `${environment.mapping.laBoundariesDataSource.href}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=AdministrativeBoundaries:laboundaries&maxFeatures=1&outputFormat=application/json&cql_filter=strToLowerCase(supplierid)='${authority.pencilId}'`;
      const geometryRequest$ = this.http.get<any>(laBoundaryWfsUri).pipe(
        filter(geoJson => geoJson.features.length === 1),
        map(geoJson => {
          const geoJsonReader = new jsts.io.GeoJSONReader();
          return geoJsonReader.read(geoJson.features[0].geometry);
        }),
        shareReplay({ refCount: false, bufferSize: 1 })
      );
      this.authorityGeometries$.set(authority, geometryRequest$);
      return geometryRequest$;
    }
  }

  /**
   * Gets type order.
   *
   * @param authorityType The authority type.
   * @returns A number.
   */
  private getTypeOrder(authorityType: string): number {
    return authorityType === KnownAuthorityType.Global ? 0 : 1;
  }
}
