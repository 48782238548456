/**
 * Details of group name returned by API.
 */
export enum GroupNames {
  /**
   * The default answers group name .
   */
  defaultAnswers = 'default-answers',

  /**
   * The default documents group name.
   */
  defaultDocuments = 'default-documents',

  /**
   * The split config group name.
   */
  splitConfig = 'split-config',

  /**
   * The help text group name .
   */
  helpText = 'help',

  /**
   * The LA Details group name .
   */
  laDetails = 'general',

  /**
   * The shortcodes group name .
   */
  shortcodes = 'shortcodes',

  /**
   * The data rules group name.
   */
  dataRules = 'data-rules',

  /**
   * The mapping group name.
   */
  mapping = 'mapping',

  /**
   * The toolbox group name.
   */
  toolbox = 'toolbox',

  /**
   * The development help text group name.
   */
  developmentHelp = 'development-help',

  /**
   * The data refresh group name.
   */
  dataRefresh = 'data-refresh',

  /**
   * The auto search group name.
   */
  autoSearch = 'auto-search',

  /**
   * The ace settings group name.
   */
  aceSettings = 'ace-settings',

    /**
     * The auto allocations group name.
     */
  autoAllocations = 'auto-allocations'
}
